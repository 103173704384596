<template>
    <v-expansion-panel :disabled="!enabled">
        <v-expansion-panel-header class="text-h5" :error="!valid" :hide-actions="hidePanelActions">
            <template v-slot:default>
                <v-row class="align-center">
                    <v-chip class="ma-0 pa-0 afs-status-indicator" :color="statusColor" :active="!!statusColor" label></v-chip>
                    <v-col
                        class="afs-markdown-gen"
                        v-html="format(entryContent)"
                    ></v-col>
                </v-row>
            </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
            <div
                v-for="content in textContentArr"
                :key="`pnl-content-${content.ItemID}`"
            >
                <div
                    class="afs-markdown-gen"
                    v-html="format(content.EntryContent)"
                ></div>
            </div>
            <v-expansion-panels
                v-for="(val, index) in SrvyElementsByParent(itemId)"
                :key="`afsSurveySection.${val.ParentID}-${val.ItemID}`"
                :parent-id="val.ParentID"
                v-model="expandedPanels[val.ItemID]"
                multiple
                accordion
            >
                <afs-survey-section
                    v-if="val.type === 'section'"
                    :entry="val"
                    :parent-enabled="enabled"
                    :ref="`${val.ParentID}-${val.ItemID}`"
                    @input="$emit('input',    {changed: true, type: 'section'})"
                    @enabledchanged="enabledChangedCb(index, $event)"
                    @validchanged="itemValidityChanged"
                ></afs-survey-section>
                <afs-survey-question
                    v-else-if="val.type === 'question'"
                    :entry="val"
                    :parent-enabled="enabled"
                    :ref="`${val.ParentID}-${val.ItemID}`"
                    @input="$emit('input',    {changed: true, type: 'question'})"
                    @enabledchanged="enabledChangedCb(index, $event)"
                    @validchanged="itemValidityChanged"
                ></afs-survey-question>
            </v-expansion-panels>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
import {mapGetters, mapState} from 'vuex'
import {AfsVuexNamespaces, PreferencesKeys, Survey} from '@/constants/state'
import AfsSurveyQuestion from '@/components/AfsSurveyQuestion'
import AfsSurveyItem from '@/mixins/afsSurveyItem'
import {ItemTypes} from '@/constants/AfsEngineTypes'
import {AfsEngineSurveyNavigationMode} from '@/constants/AfsEngineSurveyNavigationMode'

export default {
    name: 'AfsSurveySection',
    mixins: [AfsSurveyItem],
    components: { AfsSurveyQuestion },
    props: {
    },
    data: () => {
        return {
            expandedPanels: {}
        }
    },
    computed: {
        ...mapState(AfsVuexNamespaces.Surveys.mapKey, [Survey.NAVIGATION_MODE]),
        ...mapGetters(AfsVuexNamespaces.Surveys.mapKey, {
            [Survey.ELEMENTS_BY_PARENT]: Survey.ELEMENTS_BY_PARENT.getter,
        }),
        ...mapState(AfsVuexNamespaces.Preferences.mapKey, [PreferencesKeys.EXPANDED_BY_DEFAULT]),
        sectionData: function () {
            return this.entry?.Data || {}
        },
        textContentArr: function () {
            if (!this.sectionData) return []
            const ret = Object.values(this.sectionData).sort(
                (a, b) => a.RowID - b.RowID)
            return ret
        },
        hidePanelActions: function(){
            if(this.itemType.trim().toLowerCase() === ItemTypes.REQUIRED_SECTION.toLowerCase() ||
                (this[Survey.NAVIGATION_MODE].trim().toLowerCase() === AfsEngineSurveyNavigationMode.CAROUSEL.toLowerCase()) && !this.parentId)
                return true
            return false
        }
    },
    methods:{
        enabledChangedCb: function (index, event){
            if(!event) {
                this.expandedPanels[index] = []
                this.expandedPanels = [...this.expandedPanels]
            }
        },
    },
    watch: {
        defaultChildrenExpanded: function(newVal, oldVal){
            const self = this
            newVal.forEach(function (x) {
                self.$set(self.expandedPanels, x, [0])
            })
            // Should be a relatively short (<1k) list so probably negligible impact for being inefficient
            if (oldVal) {
                const removed = oldVal.filter(x => newVal.indexOf(x) < 0)
                removed.forEach(x => {
                    self.$set(self.expandedPanels, x, [])
                })
            }
        }
    },
    beforeMount() {
        this.expandedPanels = this.defaultChildrenExpanded.reduce((acc, x)=>{
            acc[x] = [0]
            return acc
        },{})
    },
}
</script>

<style scoped>
.afs-markdown-gen >>> p:only-child {
    margin-bottom: 4px;
}
</style>

<template>
    <v-dialog v-model="localShow" content-class="afs-anon-id-dlg" max-width="1024" persistent>
        <v-card>
            <v-card-title> {{dialogTitle}} </v-card-title>
            <v-card-text>
                <p v-for="(item, index) in dialogBody" :key="index" v-html="item"></p>
            </v-card-text>
            <v-card-actions>
                <v-btn v-for="(item, index) in dialogButtons" :key="index" :color="item.color" @click="onDialogButtonClick(item.next)">{{item.text}}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {AfsAnonymousIdState} from '@/constants/AfsAnonymousIdState'
import {AfsEvents} from '@/constants/events'
import {afsApi} from '@/utilities/api'

export default {
    name: 'AfsAnonymousId',
    props: {
        value: {
            type: Boolean,
            default: false
        },
        anonId: {
            type: String,
            default: 'Unknown'
        }
    },
    data: () => ({
        currentState: AfsAnonymousIdState.ANONYMOUS_ID_BEGIN,
        completed: false,
    }),
    computed: {
        localShow: {
            get: function() {return this.value},
            set: function(newVal){
                this.$emit('input', newVal)
                return newVal
            }
        },
        dialogTitle: function(){
            switch (this.currentState){
                case AfsAnonymousIdState.ANONYMOUS_ID_BEGIN:
                case AfsAnonymousIdState.ANONYMOUS_ID_CONFIRM_SEEN:
                    return 'Would you like to view your AnonymousID?'
                case AfsAnonymousIdState.ANONYMOUS_ID_VERIFY_NOT_SEEN:
                case AfsAnonymousIdState.ANONYMOUS_ID_CONFIRM_NOT_SEEN:
                    return 'You have selected to not view your AnonymousID'
                default:
                    return 'Unexpected value'
            }
        },
        bodyMap: function() {
            const anonId = this.anonId
            return {
                [AfsAnonymousIdState.ANONYMOUS_ID_BEGIN]: [
                    'Would you like to view your AnonymousID ?',
                    'For Institutional Review Board (IRB) confidentiality, your decision to receive your ' +
                        'AnonymousID is NOT being tracked.<br>' +
                        'The study administrators have no way of knowing who did and did not receive their AnonymousID.',
                    'Note that YES and NO are final; you will not have the option to change your mind later.'
                ],
                [AfsAnonymousIdState.ANONYMOUS_ID_CONFIRM_SEEN]: [
                    `Your AnonymousID is: <span class='text-h3'>${anonId}</span>`,
                    'Please write this down as this is your ONLY chance to view your AnonymousID',
                    'The study team will not be able to provide you with this in the future.',
                ],
                [AfsAnonymousIdState.ANONYMOUS_ID_VERIFY_NOT_SEEN]: [
                    'Are you sure you do not wish to receive your AnonymousID?<br>' +
                        'Note that by confirming you do not wish to see your AnonymousID, '+
                        'you will not have another chance to receive it.',
                    'The test administrators will not be able to provide your AnonymousID if you change your mind later.'
                ],
                [AfsAnonymousIdState.ANONYMOUS_ID_CONFIRM_NOT_SEEN]: [
                    'Thank you again for your participation!'
                ],
            }
        },
        dialogBody: function(){
            const state = this.currentState
            if (state in this.bodyMap)
                return (this.bodyMap[this.currentState] || [])//.map(x=> `<p>${x}</p>`)
            return []
        },
        buttonMap: function(){
            return {
                [AfsAnonymousIdState.ANONYMOUS_ID_BEGIN]: [
                    {
                        text: 'YES, SHOW ME MY ID',
                        color: 'success',
                        next: AfsAnonymousIdState.ANONYMOUS_ID_CONFIRM_SEEN
                    },
                    {
                        text: 'NO, I WOULD NOT LIKE TO SEE MY ID',
                        color: 'error',
                        next: AfsAnonymousIdState.ANONYMOUS_ID_VERIFY_NOT_SEEN
                    }
                ],
                [AfsAnonymousIdState.ANONYMOUS_ID_CONFIRM_SEEN]: [
                    {
                        text: 'CLOSE',
                        color: 'error',
                        next: AfsAnonymousIdState.ANONYMOUS_ID_DONE
                    }
                ],
                [AfsAnonymousIdState.ANONYMOUS_ID_VERIFY_NOT_SEEN]: [
                    {
                        text: 'I CONFIRM I DO NOT WISH TO VIEW',
                        color: 'success',
                        next: AfsAnonymousIdState.ANONYMOUS_ID_CONFIRM_NOT_SEEN
                    },
                    {
                        text: 'CANCEL (I WILL DECIDE LATER)',
                        color: 'error',
                        next: AfsAnonymousIdState.ANONYMOUS_ID_CANCEL
                    }
                ],
                [AfsAnonymousIdState.ANONYMOUS_ID_CONFIRM_NOT_SEEN]: [
                    {
                        text: 'CLOSE',
                        color: 'error',
                        next: AfsAnonymousIdState.ANONYMOUS_ID_DONE
                    }
                ],
            }
        },
        dialogButtons: function(){
            return this.buttonMap[this.currentState] || []
        },
    },
    methods: {
        onDialogButtonClick: function(next){
            const completeStates = [AfsAnonymousIdState.ANONYMOUS_ID_DONE, AfsAnonymousIdState.ANONYMOUS_ID_CANCEL]
            if(completeStates.indexOf(next) >= 0)
                this.localShow = false
            this.currentState = next
        }
    },
    watch: {
        currentState: function(newState, oldState){
            const completionConfirmStates = [AfsAnonymousIdState.ANONYMOUS_ID_CONFIRM_SEEN, AfsAnonymousIdState.ANONYMOUS_ID_CONFIRM_NOT_SEEN]
            const completeStates = [AfsAnonymousIdState.ANONYMOUS_ID_DONE, AfsAnonymousIdState.ANONYMOUS_ID_CANCEL]
            if(newState == oldState)
                return
            if(completeStates.indexOf(newState) >= 0){
                this.localShow = false
                this.$emit(AfsEvents.ANONYMOUS_ID_COMPLETE, {completionState: newState})
            }
            if(completionConfirmStates.indexOf(newState) >= 0){
                afsApi.submitAnonymousIdComplete()
                    .catch(()=>{
                        //shhhhh
                    })
            }
        },
        value: function(newVal, oldVal){
            if(newVal === oldVal)
                return
            if(newVal)
                this.currentState = AfsAnonymousIdState.ANONYMOUS_ID_BEGIN
        }
    }
}
</script>

<style scoped>
>>> .afs-anon-id-dlg {
    max-width: 1024px !important;
    align-self: flex-start;
}
</style>
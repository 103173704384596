<template>
    <v-skeleton-loader :loading="loading" v-if="loading"  :types="{'table-row': 'table-cell, button', 'table-thead': 'table-cell@2'}" type="heading, table-thead, table-row@3"></v-skeleton-loader>
    <v-container v-else>
        <template>
            <v-row class="text-h3 text-center justify-center">{{study}} Participant Homepage</v-row>
            <v-row>
                <v-col>
                    <v-text-field label="Participant Id" :value="participantId" readonly></v-text-field>
                </v-col>
                <v-col>
                    <v-text-field label="Email" :value="email" readonly></v-text-field>
                </v-col>
            </v-row>
            <template v-if="anonIdEligible">
                <v-row v-if="!anonIdComplete">
                    <v-col>Retrieve AnonymousID</v-col>
                    <v-col>
                        <v-btn color="primary" @click="showAnonId = true">Retrieve</v-btn>
                    </v-col>
                </v-row>
                <v-row v-if="anonIdComplete">
                    <v-col class="text-center text-h3">Thank you for Participating</v-col>
                </v-row>
            </template>
            <template v-if="!anonIdEligible || !anonIdComplete">
                <v-row v-for="questionnaire in questionnaires" :key="questionnaire.surveyId">
                    <v-col>{{questionnaire.Title}}</v-col>
                    <v-col>
                        <v-btn v-if="questionnaire.ClosedDate" color="dark" outlined @click="$router.push(questionnaire.Url)" disabled>{{ targetText(questionnaire) }}</v-btn>
                        <v-btn v-else color="primary" @click="$router.push(questionnaire.Url)" :disabled="questionnaire.Disabled">{{ targetText(questionnaire) }}</v-btn>
                    </v-col>
                </v-row>
            </template>
            <afs-anonymous-id v-model="showAnonId" :show-dialog="showAnonId" :anonId="anonId" @anonymousidcomplete="onAnonIdCompletion"></afs-anonymous-id>
        </template>
    </v-container>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import {AfsVuexNamespaces, General, UserInfo} from '@/constants/state'
import afsSessionRefresh from '@/mixins/afsSessionRefresh'
import {afsApi} from '@/utilities/api'
import strftime from 'strftime'
import {QuestionnaireProgress} from '@/constants/AfsEngineTypes'
import AfsAnonymousId from '@/components/AfsAnonymousId'
import {AfsAnonymousIdState} from '@/constants/AfsAnonymousIdState'

export default {
    name: 'HomeView',
    components: {AfsAnonymousId},
    mixins: [afsSessionRefresh],
    props: {
        study: {
            type: String,
            default: process.env.VUE_APP_AFS_ENGINE_STUDY,
        },
        title: {
            type: String,
            default: process.env.VUE_APP_AFS_ENGINE_TITLE,
        },
        skeletonLoaderTypes: {
            'afs-title': 'text',
            'afs-home-questionnaire': 'text, button',
            'afs-button': 'button',
            'table-row': 'table-cell@2',
        }
    },
    data: () => {
        return {
            questionnaires: [],
            loading: false,
            anonIdStatus: AfsAnonymousIdState.ANONYMOUS_ID_CANCEL,
            showAnonId: false,
            anonId: '',
            anonIdEligible: false,
            anonIdComplete: false,
        }
    },
    computed:{
        ...mapGetters(AfsVuexNamespaces.User.mapKey, {
            [UserInfo.ID_TOKEN]: UserInfo.ID_TOKEN.getter
        })
    },
    methods: {
        ...mapActions(AfsVuexNamespaces.User.mapKey, {
            [UserInfo.COGNITO_USER.updater]: UserInfo.COGNITO_USER.updater,
        }),
        ...mapActions(AfsVuexNamespaces.General.mapKey, [General.NAV_BAR.updater]),
        targetText: function(questionnaire){
            let cat = questionnaire.Category || 'Questionnaire'
            let status = questionnaire.Progress?.Status || QuestionnaireProgress.NOT_STARTED
            let output = ''
            let disabled = false

            if(questionnaire.ClosedDate){
                const timeStr = strftime('%e %B %Y', new Date(questionnaire.ClosedDate))
                output = `${cat} closed on ${timeStr}`
            }
            else {
                switch (status.trim().toLowerCase()) {
                    case QuestionnaireProgress.IN_PROGRESS.toLowerCase():
                        output = `Resume ${cat}`
                        break
                    case QuestionnaireProgress.FINISHED.toLowerCase():
                        output = `${cat} Completed`
                        disabled = true
                        break
                    case QuestionnaireProgress.DISABLED.toLowerCase():
                        output = `${cat} Disabled`
                        disabled = true
                        break
                    case QuestionnaireProgress.NOT_STARTED.toLowerCase():
                    default:
                        output = `Start ${cat}`
                        break
                }
                this.$set(questionnaire, 'Disabled', disabled)
            }
            return output
        },
        async retrieveQuestionnaires(){
            this.loading = true
            const questionnaires = await afsApi.getQuestionnaires()
            this.questionnaires = questionnaires
            this.loading = false
        },
        onAnonIdCompletion: function(data){
            if(data.completionState == AfsAnonymousIdState.ANONYMOUS_ID_DONE)
                this.anonIdComplete = true
        },
    },
    watch: {
        [UserInfo.ID_TOKEN]: async function(newVal){
            if(newVal)
                await this.retrieveQuestionnaires()
        }
    },
    async created(){
        if(this[UserInfo.ID_TOKEN]) {
            await this.retrieveQuestionnaires()
            await this[General.NAV_BAR.updater](true)
        }
        else {
            await this.$router.push('/login')
        }
    },
    mounted: async function() {
        try{
            const anonResp = await afsApi.getAnonymousIdEligibility()
            if(anonResp.AnonymousId.trim().toLowerCase() === 'unknown'){
                this.anonId = ''
                this.anonIdEligible = false
                this.anonIdComplete = false
            }
            else{
                this.anonId = anonResp.Eligible? anonResp.AnonymousId: 'Unknown'
                this.anonIdEligible = anonResp.Eligible
                this.anonIdComplete = anonResp.Complete
            }
        }
        catch(e){
            //... shhhh
            console.warn(e)
        }
    }
}
</script>

<style scoped>
.v-skeleton-loader {
    width: 100%;
    padding: 12px;
    margin-right: auto;
    margin-left: auto;
    max-width: 1185px;
}
>>>.v-skeleton-loader__heading{
    height: 2rem;
    margin-right: auto;
    margin-left: auto;

}
>>>.v-skeleton-loader__table-cell {
    width: 30rem !important;
}
>>>.v-skeleton-loader__button {
    width: 20rem !important;
}

</style>
<template>
    <div>
        <afs-survey></afs-survey>
    </div>
</template>

<script>
import {AfsVuexNamespaces, Snackstand, Survey} from '@/constants/state'
import { mapActions, mapState } from 'vuex'
import AfsSurvey from '@/components/AfsSurvey'
import AfsSessionRefresh from '@/mixins/afsSessionRefresh'

export default {
    name: 'SurveyView',
    mixins: [AfsSessionRefresh],
    components: { AfsSurvey },
    props: {
        surveyId: String,
    },
    methods: {
        ...mapActions(AfsVuexNamespaces.Surveys.mapKey, {
            [AfsVuexNamespaces.Surveys.initializer]:
                AfsVuexNamespaces.Surveys.initializer,
        }),
        ...mapActions(AfsVuexNamespaces.Assignments.mapKey, {
            [AfsVuexNamespaces.Assignments.initializer]:
            AfsVuexNamespaces.Assignments.initializer
        }),
        ...mapActions(AfsVuexNamespaces.Snackstand.mapKey, {
           [Snackstand.MESSAGES.insertAction]: Snackstand.MESSAGES.insertAction
        }),
    },
    computed: {
        ...mapState(AfsVuexNamespaces.Surveys.mapKey, [
            Survey.SURVEY_ID,
            Survey.SPEC_VERSION,
            Survey.SURVEY_VERSION,
            Survey.ENTRIES,
            Survey.SURVEY_ITEM_IDS,
            Survey.ENTRY_ORDER_MAP,
        ]),
    },
}
</script>

<style>
.afs-markdown-gen >>> p:only-child {
    margin-bottom: 4px;
}
</style>

// import Vue from 'vue'
// import { createStore } from 'vuex'
import {AfsVuexNamespaces, General, QuestionnaireElementKeys, Status, Survey} from '@/constants/state'
import {
    CaseInsensitiveTranslator,
    ensureObject,
    joinIfValid,
} from '@/utilities/utils'
import {afsApi} from '@/utilities/api'
import {AfsEngineSurveyNavigationMode} from '@/constants/AfsEngineSurveyNavigationMode'
import {RefToModule} from '@/classes/ElementReferenceArgs'

const registrationQuestionRegex = new RegExp('(user name|password|(first|middle|last|full|lab) name|participant email address|phone number)', 'i')

let state = () => {
    return {
        data: {},
        [Survey.SURVEY_ID]: '',
        [Survey.SURVEY_VERSION]: '',
        [Survey.SPEC_VERSION]: '',
        [Survey.SURVEY_TITLE]: '',
        [Survey.NAVIGATION_MODE]: AfsEngineSurveyNavigationMode.ACCORDION,
        // [Survey.RESULTS]: '',
        [Survey.ENTRIES]: {},
        [Survey.REFERENCES]: {},
        [Survey.SELECTOR]: '',
        [Survey.ELEMENTS_BY_PARENT]: {},
        [Survey.SURVEY_ITEM_IDS]: [],
        [Survey.ENTRY_ORDER_MAP]: {},
        [Survey.REFERENCED_IDS]: [],
        [Survey.SHOW_REVIEW]: false,
        [Survey.IS_TERMINATING]: false,
        [Survey.ALWAYS_SHOW_VALIDITY]: false,
        [Survey.PACKET_STRING]: '',
    }
}

const defaults = state()

function getChildElements(key, elemap) {
    if (!elemap) return {}

    function doLookup() {
        const filtered = Object.values(elemap).filter(
            (ele) => (ele.section || ele.Question || {}).ParentID === key
        )
        const sorted = filtered.sort(
            (a, b) =>
                (a.section || a.Question || {}).RowId -
                (b.section || b.Question || {}).RowId
        )
        const ret = sorted.map((ele) => {
            const myId = joinIfValid({
                vals: [
                    (ele.section || ele.Question || {}).ParentID,
                    (ele.section || ele.Question || {}).ItemID,
                ],
            })
            const itemId = (ele.section || ele.Question || {}).ItemID
            const children = getChildElements(itemId, elemap)
            return {
                id: myId,
                children: children,
            }
        })
        return ret
    }

    return doLookup()
}

let mutations = {
    [AfsVuexNamespaces.Surveys.setter](state, payload) {
        state.data = ensureObject(payload)
        const trans = new CaseInsensitiveTranslator(payload)
        const prefixRegex = RegExp(`^${Survey.prefix}`)
        for (const i of [
            Survey.ENTRIES,
            Survey.REFERENCES,
            Survey.SURVEY_ID,
            Survey.SPEC_VERSION,
            Survey.SURVEY_VERSION,
            Survey.SELECTOR,
            Survey.SURVEY_TITLE,
            Survey.NAVIGATION_MODE,
            Survey.PACKET_STRING,
        ]) {
            const respKey = i.replace(prefixRegex, '')
            state[i] = trans[respKey]
        }

        if (!state[Survey.REFERENCES])
            state[Survey.REFERENCES] = trans['surveys']

        if (state[Survey.ENTRIES]) {
            state[Survey.ENTRIES] = Object.values(state[Survey.ENTRIES]).reduce(
                (acc, ele) => {
                    const flat = ele.section || ele.Question || {}
                    acc[flat.ItemID] = {
                        ...flat,
                        ...ele,
                    }
                    return acc
                },
                {}
            )
            state[Survey.ENTRIES + '2'] = Object.values(
                state[Survey.ENTRIES]
            ).reduce((acc, ele) => {
                const rawParentId = (ele.section || ele.Question || {}).ParentID
                const parent = state[Survey.ENTRIES][rawParentId]
                const parentId = joinIfValid({
                    vals: [parent?.ParentID, parent?.ItemID],
                })
                const oKey = joinIfValid({vals: [rawParentId, ele.ItemID]})

                acc[oKey] = {...ele}
                if (acc[oKey])
                    acc[oKey].ParentID = parentId
                return acc
            }, {})
        }

        state[Survey.SURVEY_ITEM_IDS] = Object.keys(state[Survey.ENTRIES])

        state[Survey.REFERENCED_IDS] = Object.values(
            state[Survey.REFERENCES] || []
        ).reduce((acc, ele) => {
            acc.push(ele.variable)
            return acc
        }, [])

        state[Survey.SHOW_REVIEW] = false
    },
    [Survey.SURVEY_ID.setter](state, surveyId){
        state[Survey.SURVEY_ID] = surveyId
    },
    [Survey.SHOW_REVIEW.setter](state, payload){
        state[Survey.SHOW_REVIEW] = !!payload
    },
    [Survey.IS_TERMINATING.setter](state, payload){
        state[Survey.IS_TERMINATING] = !!payload
    },
    [Survey.ALWAYS_SHOW_VALIDITY.setter](state, payload){
        state[Survey.ALWAYS_SHOW_VALIDITY] = !!payload
    },
    [Status.CLEAR.setter](state, payload){
        Object.assign(state, defaults)
        if(payload) state[Status.INITIALIZED] = this[Status.INITIALIZED]
    },

}

let actions = {
    async [AfsVuexNamespaces.Surveys.initializer]({commit, state}) {
        /* TODO: determine aay to do this more generally */
        let resp = {}
        if(state[Survey.SURVEY_ID].trim().toLowerCase() === 'registration')
            resp = await afsApi.getOpenSurvey(state[Survey.SURVEY_ID])
        else
            resp = await afsApi.getSurvey(state[Survey.SURVEY_ID])
        commit(AfsVuexNamespaces.Surveys.setter, resp)
        const retVal = state[Survey.ENTRIES]
        return retVal
    },
    [Survey.SURVEY_ID.updater]({commit}, surveyId){
        commit(Survey.SURVEY_ID.setter, surveyId)
    },
    [Survey.SHOW_REVIEW.updater]({commit}, value){
        commit(Survey.SHOW_REVIEW.setter, value)
    },
    [Survey.SHOW_REVIEW.toggle]({commit, state}){
        commit(Survey.SHOW_REVIEW.setter, !state[Survey.SHOW_REVIEW])
    },
    async [Survey.RESPONSES.initializer]({commit, state, rootGetters}){
        let resp = {}
        resp = await afsApi.getResults({surveyId: state[Survey.SURVEY_ID], in_progress: true})
        const responseMap = Object.values(resp?.Results || {})
            .reduce((acc, x)=>{
                acc[x.QuestionId] = x
                return acc
            }, {})
        Object.values(state[Survey.ENTRIES])
            .filter(x=>x.Question)
            .forEach(x => {
                const resId = rootGetters[`${AfsVuexNamespaces.General.mapKey}${General.REF_AS_MODULE.getter}`](
                    new RefToModule({
                        questionnaireId: state[Survey.SURVEY_ID],
                        itemId: x.ItemID
                    })
                )
                commit(`${resId}/${QuestionnaireElementKeys.RESPONSE.setter}`, responseMap[x.ItemID]?.Answer || [], {root: true})
            })
    },
    [Survey.IS_TERMINATING.updater]({commit}, payload){
        commit(Survey.IS_TERMINATING.setter, payload)
    },
    [Survey.ALWAYS_SHOW_VALIDITY.updater]({commit}, payload){
        commit(Survey.ALWAYS_SHOW_VALIDITY.setter, payload)
    },
    [Status.CLEAR.updater]({commit}, payload){
        commit(Status.CLEAR.setter, payload)
    },
}

let getters = {
    [Survey.REFERENCES.getter]:
        (state) =>
            (...ids) => {
                let ret = []
                if (ids.length > 0) {
                    ret = ids.reduce((acc, ele) => {
                        if (state[Survey.REFERENCES][ele]) acc.push(ele.variable)
                        return acc
                    }, [])
                }
                else {
                    ret = Object.values(state[Survey.REFERENCES] || {}).reduce((acc, ele)=>{
                        const outSurveyId = Survey.isCurrent(ele.variable)? state[Survey.SURVEY_ID]: ele.variable
                        acc.push(outSurveyId)
                        return acc
                    }, [])
                }

                return ret
            },
    [Survey.ENTRY_ORDER_MAP.getter]: (state) => {
        return getChildElements(null, state[Survey.ENTRIES])
    },
    [Survey.ELEMENTS_BY_PARENT.getter]: (state) => (id=null) => {
        const safeId = id ?? ''
        const ret = Object.values(state[Survey.ENTRIES] || {})
            .filter((x) => (x.ParentID || '') === safeId)
            .sort((a, b) => a.RowID - b.RowID)
        return ret
    },
    [Survey.IS_REGISTRATION.getter]: (state)  => {
        return Survey.isRegistration(state[Survey.SURVEY_ID] || '')
    },
    [Survey.REGISTRATION_KEYS.getter]: (state, getters) => {
        if (!getters[Survey.IS_REGISTRATION.getter])
            return []
        const rawEntries = state[Survey.ENTRIES]  || {}
        const entries = Object.values(rawEntries)
            .filter(ele => ele.EntryContent.trim().toLowerCase().match(registrationQuestionRegex))
            .reduce((acc, ele) => {
                acc.push(ele.ItemID, joinIfValid({vals: [ele.ParentID, ele.ItemID]}))
                return acc
            }, [])
        return [...(new Set(entries))]
    },
    [Survey.REGISTRATION.getter]: (state, getters, rootState, rootGetters) => {
        if(!getters[Survey.IS_REGISTRATION.getter])
            return {}
        const entries = Object.values(state[Survey.ENTRIES]  || {})
            .filter( ele => ele.EntryContent.trim().toLowerCase().match(registrationQuestionRegex))
            .reduce((acc, ele) => {
                let authKey = ''
                const matched = ele.EntryContent.trim().toLowerCase().match(registrationQuestionRegex)[0]
                switch(matched){
                    case 'user name':
                    case 'participant email address':
                    case 'user':
                    case 'email':
                        authKey = 'email'
                        break
                    case 'password':
                        authKey = 'password'
                        break
                    case 'first name':
                        authKey = 'firstName'
                        break
                    case 'middle name':
                        authKey = 'middleName'
                        break
                    case 'last name':
                        authKey = 'lastName'
                        break
                    case 'phone':
                    case 'phone number':
                        authKey = 'phoneNumber'
                        break
                    case 'lab name':
                        authKey = 'labName'
                        break
                    default:
                        authKey = matched
                        break
                }
                acc.push({parentId: ele.ParentID, questionId: ele.ItemID, authKey: authKey})
                return acc
            }, [])
        const retVal = entries.reduce((acc, ele) => {
            const resId = rootGetters[`${AfsVuexNamespaces.General.mapKey}${General.REF_AS_MODULE.getter}`](
                new RefToModule({
                    questionnaireId: state[Survey.SURVEY_ID],
                    itemId: ele.questionId
                })
            )
            if(!rootState[resId])
                return acc
            acc[ele.authKey] = rootState[resId][QuestionnaireElementKeys.RESPONSE].map(x=>x.AnswerText).join(' ')
            return acc
        }, {})
        return retVal
    },
    [Survey.COMPLETE.getter]: (_, getters) => {
        const topsComplete = getters[Survey.TOPS_COMPLETE.getter]
        return topsComplete.every(x=>x)
    },
    [Survey.TOPS_COMPLETE.getter]: (state, getters, rootState, rootGetters) =>{
        const tops = getters[Survey.ELEMENTS_BY_PARENT.getter]()
        if(tops.length < 1)
            return [false]
        const topStates = tops.map(x=>{
            const refId = rootGetters[`${AfsVuexNamespaces.General.mapKey}${General.REF_AS_MODULE.getter}`](
                new RefToModule({
                    questionnaireId: state[Survey.SURVEY_ID],
                    itemId: x.ItemID
                })
            )

            if(!(refId in rootState))
                return false
            return rootGetters[`${refId}/${QuestionnaireElementKeys.COMPLETE.getter}`]
        })
        return topStates
    },
    [Survey.RESPONSES.getter]: (state, getters, rootState, rootGetters) =>{
        let regIds = new Set(getters[Survey.REGISTRATION_KEYS.getter] || [])
        const retVal = Object.values(state[Survey.ENTRIES] || {})
            .filter(ele =>{
                if(!ele.Question)
                    return false
                if(regIds.has(ele.ItemID))
                    return false
                const refId = rootGetters[`${AfsVuexNamespaces.General.mapKey}${General.REF_AS_MODULE.getter}`](
                    new RefToModule({
                        questionnaireId: state[Survey.SURVEY_ID],
                        itemId: ele.ItemID
                    })
                )
                if(!rootState[refId])
                    return false
                if(!rootGetters[`${refId}/${QuestionnaireElementKeys.ENABLED.getter}`])
                    return false
                return rootGetters[`${refId}/${QuestionnaireElementKeys.COMPLETE.getter}`]
            })
            .sort((a, b) => a.RowID - b.RowID)
            .map(ele=>{
                const refId = rootGetters[`${AfsVuexNamespaces.General.mapKey}${General.REF_AS_MODULE.getter}`](
                    new RefToModule({
                        questionnaireId: state[Survey.SURVEY_ID],
                        itemId: ele.ItemID
                    })
                )
                return {
                    QuestionId: ele.Question.ItemID,
                    Question: ele.Question.EntryContent,
                    Answer: rootState[refId][QuestionnaireElementKeys.RESPONSE]
                }
            })
        return retVal
    },
    [Survey.TOPS_ALWAYS_EXPANDED.getter]: (state, getters, rootState, rootGetters) =>{
        const tops = getters[Survey.ELEMENTS_BY_PARENT.getter]()
        if(tops.length < 1)
            return []
        const topsExpanded = tops.reduce((acc, x)=>{
            const refId = rootGetters[`${AfsVuexNamespaces.General.mapKey}${General.REF_AS_MODULE.getter}`](
                new RefToModule({
                    questionnaireId: state[Survey.SURVEY_ID],
                    itemId: x.ItemID
                })
            )

            if(refId in rootState && rootGetters[`${refId}/${QuestionnaireElementKeys.ALWAYS_EXPANDED.getter}`])
                acc.push(x.ItemID)
            return acc
        }, [])
        return topsExpanded
    },
}

export default {
    modules: {},
    namespaced: true,
    state: state,
    mutations: mutations,
    getters: getters,
    actions: actions,
}

export class AfsEvents {
    static get EnabledChanged(){
        return 'enabledchanged'
    }

    static get VALID_CHANGED(){
        return 'validchanged'
    }

    static get ANONYMOUS_ID_COMPLETE(){
        return 'anonymousidcomplete'
    }
}
<template>
    <v-row class="v-input pa-0 ma-0">
        <label class="col-12 v-label pa-0 text--secondary">{{packetActivatorText}}</label>
        <a class="text--lighten-3" @click="packetActivatorClick">Download {{packetString}}</a>
        <a class="d-none" :href="packetDownloadUrl" target="_blank" ref="afsEnginePacketActivator"></a>
    </v-row>
</template>

<script>
import {afsApi} from '@/utilities/api'
import {AfsVuexNamespaces, PacketAssignment, Survey} from '@/constants/state'
import {mapGetters, mapState} from 'vuex'

export default {
    name: 'AfsPacketActivator.vue',
    data: () =>({
        packetDownloadUrl: ''
    }),
    computed: {
        ...mapState(AfsVuexNamespaces.Surveys.mapKey, [Survey.SURVEY_ID, Survey.PACKET_STRING]),
        ...mapState(AfsVuexNamespaces.Assignments.mapKey, [PacketAssignment.CURRENT_INDEX, PacketAssignment.ASSIGNMENT_ID]),
        ...mapGetters(AfsVuexNamespaces.Assignments.mapKey, {
            [PacketAssignment.CURRENT]: PacketAssignment.CURRENT.getter,
            [PacketAssignment.NUM_PACKETS]: PacketAssignment.NUM_PACKETS.getter,
        }),
        packetString: function(){
            return this[Survey.PACKET_STRING]
        },
        packetActivatorText: function(){
            if(!this[PacketAssignment.CURRENT])
                return ''
            return `${this[PacketAssignment.CURRENT]?.Packet} (${parseInt(this[PacketAssignment.CURRENT_INDEX])+1}/${this[PacketAssignment.NUM_PACKETS]})`
        },
    },
    methods: {
        packetActivatorClick: async function(){
            const dlUrl = await afsApi.getPacketDownload(this[Survey.SURVEY_ID], '', this[PacketAssignment.ASSIGNMENT_ID], this[PacketAssignment.CURRENT]?.Packet)
            this.packetDownloadUrl = dlUrl
        },
    },
    watch:{
        packetDownloadUrl: async function(newVal){
            if(!newVal)
                return
            await this.$nextTick()
            this.$refs.afsEnginePacketActivator.click()
        }
    }
}
</script>

<style scoped>
label {
    font-size: 14px;
    max-width: 133%;
    pointer-events: auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
</style>